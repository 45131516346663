// Generated by Framer (70e732d)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["zcpwC8J3a", "PmLVT3BAs"];

const variantClassNames = {PmLVT3BAs: "framer-v-le2j7e", zcpwC8J3a: "framer-v-wp6i0i"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Divider / Offset, Opacity": "PmLVT3BAs", Divider: "zcpwC8J3a"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "zcpwC8J3a", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "zcpwC8J3a", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-WkFXa", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-wp6i0i", className)} data-framer-name={"Divider"} layoutDependency={layoutDependency} layoutId={"zcpwC8J3a"} ref={ref} style={{backgroundColor: "var(--token-15b82c99-7fa7-4721-ad0d-59d3528767e1, rgb(201, 200, 200))", opacity: 1, ...style}} transition={transition} variants={{PmLVT3BAs: {backgroundColor: "var(--token-afb97292-18f9-440f-b246-9a920deed526, rgb(14, 14, 14))", opacity: 0}}} {...addPropertyOverrides({PmLVT3BAs: {"data-framer-name": "Divider / Offset, Opacity"}}, baseVariant, gestureVariant)}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-WkFXa [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-WkFXa .framer-191x8ir { display: block; }", ".framer-WkFXa .framer-wp6i0i { height: 1px; position: relative; width: 788px; }", ".framer-WkFXa.framer-v-le2j7e .framer-wp6i0i { width: 1px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1
 * @framerIntrinsicWidth 788
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"PmLVT3BAs":{"layout":["fixed","fixed"]}}}
 */
const FramerklWH2S0X2: React.ComponentType<Props> = withCSS(Component, css, "framer-WkFXa") as typeof Component;
export default FramerklWH2S0X2;

FramerklWH2S0X2.displayName = "Divider";

FramerklWH2S0X2.defaultProps = {height: 1, width: 788};

addPropertyControls(FramerklWH2S0X2, {variant: {options: ["zcpwC8J3a", "PmLVT3BAs"], optionTitles: ["Divider", "Divider / Offset, Opacity"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerklWH2S0X2, [])